import revive_payload_client_gBor5OZNui from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tj25okb7lu6pkkm6ypc4whj6y4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_b5AembhrwW from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tj25okb7lu6pkkm6ypc4whj6y4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_0bIiycuUvc from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tj25okb7lu6pkkm6ypc4whj6y4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_pAgjtg8Zhd from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tj25okb7lu6pkkm6ypc4whj6y4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_3irYciDBcz from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tj25okb7lu6pkkm6ypc4whj6y4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_jPQ6e2Xe4U from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tj25okb7lu6pkkm6ypc4whj6y4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_GpotXhH435 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tj25okb7lu6pkkm6ypc4whj6y4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/platform-admin/.nuxt/components.plugin.mjs";
import prefetch_client_kyW5TnbsLC from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tj25okb7lu6pkkm6ypc4whj6y4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_HDUXJV8zNT from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.22.7_magicast@0.2.11_rollup@4.18.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import tooltip_hCDS0PLZBq from "/vercel/path0/node_modules/.pnpm/@docue+docue-ui-v2@3.2.0_magicast@0.2.11_nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher_r425khg54fnpfpeja42bk5qd4a/node_modules/@docue/docue-ui-v2/ui-plugins/tooltip.ts";
import bugsnag_tkMQfOjcVZ from "/vercel/path0/apps/platform-admin/plugins/bugsnag.ts";
export default [
  revive_payload_client_gBor5OZNui,
  unhead_b5AembhrwW,
  router_0bIiycuUvc,
  payload_client_pAgjtg8Zhd,
  navigation_repaint_client_3irYciDBcz,
  check_outdated_build_client_jPQ6e2Xe4U,
  chunk_reload_client_GpotXhH435,
  components_plugin_KR1HBZs4kY,
  prefetch_client_kyW5TnbsLC,
  plugin_HDUXJV8zNT,
  tooltip_hCDS0PLZBq,
  bugsnag_tkMQfOjcVZ
]